import React from 'react'
import './header.css';

function  Header() {
  return (
    <div className="header">
        <h1>Edvora</h1>
        <div>
            <h1 style={{color:"white"}}>dhruv singh</h1>
        
        </div>

    </div>
  )
}

export default  Header